import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome"
    }}>{`Welcome`}</h1>
    <p>{`Thank you for choosing Realigned Technologies`}</p>
    <p>{`You can find our product documentation below`}</p>
    <br />
    <h2 {...{
      "id": "jira-apps"
    }}>{`Jira Apps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/journeymapping"
        }}>{`Journey Mapping for Jira`}</a></li>
    </ul>
    <h2 {...{
      "id": "confluence-apps"
    }}>{`Confluence Apps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/usageguide"
        }}>{`Enhanced Sharing for Confluence`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/introduction"
        }}>{`Personal Dashboards for Confluence`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/readconfirmations"
        }}>{`Read Confirmations for Confluence`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      